<template>
  <div>

    <Spinner v-if="loading === true" />

    <div
      v-if="loading === false"
      class="row"
    >
      <div class="col-md-6" />
      <div class="col-md-6 create-button">
        <button
          id="deleteMultiple"
          type="button"
          class="btn btn-outline-primary mb-2"
          @click="confirmDeleteText(deletedArry)"
        >
          Delete Selected Elements
        </button>
        <button
          type="button"
          class="btn btn-primary mb-2 ml-1"
          data-toggle="modal"
          data-target="#createModal"
        >
          Create
        </button>
        <create
          v-if="$router.currentRoute.name === 'admins'"
          @refresh="getRoles()"
        />
      </div>
    </div>
    <basic-table
      v-if="loading === false"
      ref="table"
      :columns="columns"
      :values="rows"
      :actions-obj="actionsArray"
      @dynamicAction="handleActions($event)"
      @choosenObject="getEditedObject($event)"
      @deleteItems="removeItems($event)"
      @deletedMultipleIds="catchIds($event)"
    />
    <edit
      v-if="$router.currentRoute.name === 'admins'"
      :admin="editedAdmin"
      @refresh="getRoles()"
    />
  </div>
</template>
<script>
import { BRow, BCol } from 'bootstrap-vue'
import BasicTable from '../../../shared/tables/BasicTable.vue'
import Create from './create.vue'
import Edit from './edit.vue'
import adminService from '../../../services/adminService'
import Roles from './roles.vue'
import ResetPassword from './resetPassword.vue'
import Spinner from '@/shared/loader/Spinner'

export default {
  components: {
    Spinner,
    BRow,
    BCol,
    Create,
    Edit,
    ResetPassword,
    Roles,
    BasicTable,
  },
  data() {
    return {
      loading: false,
      userId: '',
      actionsArray: [],
      editedAdmin: '',
      roleId: '',
      columns: [
        {
          label: 'Full Name',
          field: 'full_name',
        },
        {
          label: 'Email',
          field: 'email',
        },
        {
          label: 'Role',
          field: 'role_name',
        },
        {
          label: 'Action',
          field: 'action',
        },
      ],
      rows: [],
      searchTerm: '',
    }
  },
  created() {
    this.getRoles()
    // this.actionsArray = [{ title: 'Roles', icon: 'ShieldIcon' }, { title: 'Reset Password', icon: 'KeyIcon' }]
  },
  methods: {
    getRoles() {
      this.loading = true
      adminService.users('Super_Admin').then(response => {
        this.rows = response.data.data
        this.loading = false
      })
    },
    handleActions(itemObj) {
      switch (itemObj.key) {
        case 'Reset Password': {
          this.userId = itemObj.id
          $('#resetPasswordModal').modal('show')
        }
          break
        case 'Roles': {
          this.roleId = itemObj.id
          $('#rolesModal').modal('show')
        }
          break
        default:

      }
      // this.$router.push({ name: 'representatives', params: { id: itemId } })
    },
    getEditedObject(obj) {
      this.editedAdmin = obj
    },
    catchIds(ids) {
      this.deletedArry = ids
    },
    removeItems(arr) {
      this.loading = true
      this.confirmDeleteText(arr)
    },
    confirmDeleteText(arr) {
      if (arr.length > 0) {
        this.$swal({
          title: 'Are you sure?',
          text: "You won't be able to revert this!",
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Yes, delete it!',
          customClass: {
            confirmButton: 'btn btn-primary',
            cancelButton: 'btn btn-outline-danger ml-1',
          },
          buttonsStyling: false,
        }).then(result => {
          if (result.value) {
            adminService.deleteAdmin(arr.join()).then(response => {
              if (response && response.status === 200) {
                this.$swal({
                  icon: 'success',
                  title: 'Deleted!',
                  text: 'Your Items have been deleted.',
                  customClass: {
                    confirmButton: 'btn btn-success',
                  },
                }).then(() => {
                  this.renderTable()
                })
              }
            }).catch(error => {
              this.loading = false
              return Promise.reject(error)
            })
          } else if (result.dismiss === 'cancel') {
            this.$swal({
              title: 'Cancelled',
              text: '',
              icon: 'error',
              customClass: {
                confirmButton: 'btn btn-success',
              },
            })
            this.loading = false
          } else {
            this.loading = false
          }
        })
      }
    },
    renderTable() {
      this.getRoles()
    },

  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-good-table.scss';
</style>

